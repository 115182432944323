<template>
  <div class="note-app">
    <div class="sidebar" :class="{ active: isSidebarOpen }">
      <div class="header">
        <h2>笔记目录</h2>
        <Input
          v-model="searchQuery"
          type="text"
          placeholder="搜索..."
          class="search-input"
          @on-enter="getNoteList"
        />
        <div class="button-group">
          <Button @click="showAddFolderDialog" class="action-button primary">
            <Icon type="ios-folder" /> 新增目录
          </Button>
          <Button @click="showAddNoteDialog" class="action-button primary">
            <Icon type="ios-paper" /> 新增笔记
          </Button>
        </div>
      </div>
      <div class="tree-wrapper">
        <Tree
          :data="treeData"
          :render="renderTreeNode"
          class="demo-tree-render"
          :expand-node="true"
        />
      </div>
    </div>
    <div class="content" :class="{ shift: isSidebarOpen }">
      <div v-if="selectedNote" class="note-details">
        <div class="note-header">
          <Button
            v-if="isEditing"
            @click="saveNote"
            class="action-button save-button"
            >保存</Button
          >
          <Button
            v-if="!isEditing"
            @click="editNote"
            class="action-button edit-button"
            >编辑</Button
          >
          <Button @click="downloadNote" class="action-button download-button"
            >下载</Button
          >
          <Button @click="toggleFullscreen" class="action-button secondary"
            >全屏</Button
          >
        </div>
        <div v-if="isEditing" class="note-editor">
          <Input
            v-model="selectedNote.noteTitle"
            type="text"
            placeholder="笔记标题"
            class="title-input"
          />
          <MarkdownPro
            v-model="selectedNote.noteContent"
            :exportFileName="selectedNote.noteTitle"
            class="markdown-editor"
            :options="editorOptions"
            @on-copy="onCopy"
            :toolbars="toolbars"
          />
        </div>
        <div v-else class="note-preview">
          <h3>{{ selectedNote.noteTitle }}</h3>
          <MarkdownPreview
            :initialValue="selectedNote.noteContent"
            class="markdown-preview"
            @on-copy="onCopy"
          />
        </div>
      </div>
      <div v-else-if="!selectedNote" class="placeholder">
        <div class="placeholder-content">
          <h3>没有选择笔记</h3>
          <p>请在左侧目录中选择一个笔记以查看详细内容。</p>
        </div>
      </div>
    </div>

    <Modal
      v-model="showAddFolder"
      title="新增目录"
      class="ivu-modal"
      :mask-closable="false"
    >
      <Card>
        <div class="modal-body">
          <label for="folderTitle" class="required">目录名称</label>
          <Input
            id="folderTitle"
            v-model="newFolderTitle"
            type="text"
            placeholder="请输入目录名称"
            class="folder-input"
          />
          <label for="parentFolder" class="noRequired">选择父级目录</label>
          <Treeselect
            id="parentFolder"
            v-model="selectedAddFolderFolderId"
            :options="folderTreeData"
            placeholder="请选择父级目录"
            class="folder-select"
          />
        </div>
      </Card>
      <div slot="footer">
        <Button type="primary" @click="closeAddFolderDialog">取消</Button>
        <Button type="primary" @click="addFolder">确定</Button>
      </div>
    </Modal>

    <Modal v-model="showAddNote" title="新增笔记" class="ivu-modal">
      <Card>
        <div class="modal-body">
          <label for="noteTitle" class="required">笔记标题</label>
          <Input
            id="noteTitle"
            v-model="newNoteTitle"
            type="text"
            placeholder="请输入笔记标题"
            class="note-input"
          />
          <label for="noteFolder" class="required">选择目录</label>
          <Treeselect
            id="noteFolder"
            v-model="selectedAddNoteFolderId"
            :options="noteFolderTreeData"
            placeholder="请选择目录"
            class="note-select"
          />
        </div>
      </Card>
      <div slot="footer">
        <Button type="primary" @click="closeAddNoteDialog">取消</Button>
        <Button type="primary" @click="addNote">确定</Button>
      </div>
    </Modal>

    <Modal
      v-model="showDeleteConfirm"
      title="确认删除"
      class="ivu-modal"
      @on-ok="confirmDelete"
      @on-cancel="cancelDelete"
    >
      <p>{{ deleteModalContent }}</p>
    </Modal>
  </div>
</template>

<script>
import {
  listNotes,
  saveNote,
  modifyNote,
  saveDirectory,
  deleteDirectory,
  deleteNote,
  modifyNoteDirectory,
} from "@/api/note";
import { MarkdownPro, MarkdownPreview } from "vue-meditor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Message } from "view-design";

export default {
  components: {
    MarkdownPro,
    MarkdownPreview,
    Treeselect,
  },
  data() {
    return {
      toolbars: {
        h4: true,
        h5: true,
        h6: true,
        uploadImage: true,
        print: true,
        save: true,
        clear: true,
      },
      folders: [],
      selectedNote: null,
      searchQuery: "",
      isEditing: false,
      showAddFolder: false,
      showAddNote: false,
      showDeleteConfirm: false,
      newFolderTitle: "",
      newNoteTitle: "",
      selectedFolderId: "",
      deleteTarget: null,
      deleteType: "", 
      deleteModalContent: "确认删除该目录？操作不可撤销！",
      editorOptions: {
        placeholder: "在此输入笔记内容...",
        theme: "dark",
      },
      isSidebarOpen: false,
      selectedAddFolderFolderId: null,
      selectedAddNoteFolderId: null,
      deleteNoteDirectoryId: null,
      editFolderTitle: "",
      editingFolderId: null, 
    };
  },
  computed: {
    treeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => {
          let children = folder.notesList.map((note) => ({
            noteTitle: note.noteTitle,
            isLeaf: true,
            noteId: note.noteId,
            noteContent: note.noteContent,
            directoryId: note.directoryId,
          }));
          if (folder.children && folder.children.length > 0) {
            children = children.concat(buildTree(folder.children));
          }
          return {
            parentId: folder.parentId,
            directoryName: folder.directoryName,
            children: children,
            directoryId: folder.directoryId,
            isOpen: folder.isOpen,
            listOrder: folder.listOrder,
          };
        });
      };
      return buildTree(this.folders);
    },
    folderTreeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => ({
          id: folder.directoryId,
          label: folder.directoryName,
          children:
            folder.children && folder.children.length > 0
              ? buildTree(folder.children)
              : [],
        }));
      };
      return [
        {
          id: null, 
          label: "根目录",
          children: buildTree(this.folders),
        },
      ];
    },
    noteFolderTreeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => ({
          id: folder.directoryId,
          label: folder.directoryName,
          children:
            folder.children && folder.children.length > 0
              ? buildTree(folder.children)
              : [],
        }));
      };
      return buildTree(this.folders);
    },
  },
  mounted() {
    this.getNoteList();
  },
  methods: {
    updateFolder() {
      if (!this.editFolderTitle.trim()) {
        Message.error("目录名称不能为空！");
        return;
      }
      const folder = this.folders.find(
        (f) => f.directoryId === this.editingFolderId
      );
      const modifyId = this.editingFolderId;
      const modifyTitle = this.editFolderTitle;
      this.traverseDirectories(this.folders, (folder) => {
        if (folder.directoryId === modifyId) {
          folder.directoryName = modifyTitle;
          modifyNoteDirectory(folder).then((res) => {
            if (res.code === 0) {
              Message.success("目录名称修改成功");
              this.editingFolderId = null;
            } else {
              Message.error("目录名称修改失败：" + res.msg);
            }
          });
        }
      });
    },
    getNoteList() {
      listNotes({ search: this.searchQuery }).then((res) => {
        if (res.code !== 0) {
          Message.error(res.msg);
          return;
        }
        const list = res.retVal;
        this.traverseDirectories(list, (directory) => {
          directory.isOpen = false;
        });
        this.folders = list;
      });
    },
    toggleFolder(folder) {
      folder.isOpen = !folder.isOpen;
    },
    selectNote(note) {
      this.selectedNote = note;
      this.isEditing = false;
    },
    confirmDeleteNode(node) {
      if (node.isLeaf) {
        this.confirmDeleteNote(node.directoryId, node.noteId);
      } else {
        this.confirmDeleteFolder(node.directoryId);
      }
    },
    startEditFolder(folder) {
      this.editingFolderId = folder.directoryId;
      this.editFolderTitle = folder.directoryName;
    },
    renderTreeNode(h, { data }) {
      const isSelected =
        this.selectedNote && data.noteId === this.selectedNote.noteId;

      const isEditing = this.editingFolderId === data.directoryId;

      return (
        <div
          style={{
            cursor: "pointer",
            backgroundColor:
              isSelected && data.isLeaf ? "#e0f7fa" : "transparent",
            padding: "5px 10px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            this.handleNodeClick(data);
          }}
        >
          <Icon
            type={data.isLeaf ? "ios-paper-outline" : "ios-folder-outline"}
            style={{ marginRight: "8px" }}
          />
          {isEditing && !data.isLeaf ? (
            <Input
              v-model={this.editFolderTitle}
              style={{ flex: 1 }}
              onOn-blur={() => this.updateFolder(data)}
              onOn-enter={(event) => {
                this.updateFolder(data);
              }}
              autofocus
            />
          ) : (
            <span style={{ flex: 1 }}>
              {data.isLeaf ? data.noteTitle : data.directoryName}
            </span>
          )}

          {/* 添加编辑目录名称的图标按钮 */}
          {!data.isLeaf && (
            <Icon
              type="ios-create-outline"
              style={{ color: "blue", cursor: "pointer", marginLeft: "10px" }}
              onClick={(event) => {
                event.stopPropagation();
                this.startEditFolder(data);
              }}
            />
          )}
          <Icon
            type="ios-trash"
            style={{ color: "red", cursor: "pointer", marginLeft: "10px" }}
            onClick={(event) => {
              event.stopPropagation();
              this.confirmDeleteNode(data);
            }}
          />
        </div>
      );
    },
    handleNodeClick(node) {
      if (node.isLeaf) {
        this.selectNote(node);
      } else {
        this.toggleFolder(node);
      }
    },
    onCopy(val) {
      this.$Message.success("复制成功");
    },
    toggleFolder(folder) {
      folder.isOpen = !folder.isOpen;
    },
    selectNote(note) {
      this.selectedNote = note;
      this.isEditing = false;
    },
    showAddFolderDialog() {
      this.showAddFolder = true;
    },
    closeAddFolderDialog() {
      this.showAddFolder = false;
      this.newFolderTitle = "";
      this.selectedAddFolderFolderId = null;
    },
    addFolder() {
      if (!this.newFolderTitle.trim()) {
        Message.error("目录名称不能为空！");
        return;
      }
      let newFolder = {
        directoryName: this.newFolderTitle,
        notesList: [],
        isOpen: true,
        parentId: this.selectedAddFolderFolderId,
      };
      const that = this;
      const parentId = this.selectedAddFolderFolderId;
      saveDirectory(newFolder).then((res) => {
        if (res.code === 0) {
          newFolder.directoryId = res.retVal;
          if (parentId) {
            that.addFolderToParent(that.folders, parentId, newFolder);
          } else {
            that.folders.push(newFolder);
          }
          Message.success("目录添加成功");
        } else {
          Message.error("目录添加失败:" + res.msg);
          return;
        }
      });
      this.closeAddFolderDialog();
      this.restoreExpandState();
    },
    showAddNoteDialog() {
      this.selectedFolderId =
        this.folders.length > 0 ? this.folders[0].directoryId : null;
      if (this.folders.length === 0) {
        Message.error("请先新增目录");
        return;
      }
      this.showAddNote = true;
    },
    closeAddNoteDialog() {
      this.showAddNote = false;
      this.newNoteTitle = "";
      this.selectedAddNoteFolderId = null;
    },
    addNote() {
      if (!this.newNoteTitle) {
        this.$message.error("请填写笔记名称！");
        return;
      }
      if (!this.selectedAddNoteFolderId) {
        Message.error("请选择目录！");
        return;
      }
      let newNote = {
        noteTitle: this.newNoteTitle,
        directoryId: this.selectedAddNoteFolderId,
        noteContent: "",
        noteId: null,
      };
      saveNote(newNote).then((res) => {
        if (res.code === 0) {
          newNote.noteId = res.retVal;
          this.$Message.success("笔记添加成功");
        } else {
          this.$Message.error("笔记添加失败");
        }
      });
      let addNoteFolderId = this.selectedAddNoteFolderId;
      this.traverseDirectories(this.folders, (folder) => {
        if (folder.directoryId === addNoteFolderId) {
          folder.notesList.push(newNote);
        }
      });
      this.selectedNote = newNote;
      this.isEditing = true;
      this.closeAddNoteDialog();
      this.restoreExpandState();
    },
    editNote() {
      this.isEditing = true;
    },
    saveNote() {
      let modifyNoteData = {
        noteId: this.selectedNote.noteId,
        noteTitle: this.selectedNote.noteTitle,
        noteContent: this.selectedNote.noteContent,
      };
      modifyNote(modifyNoteData).then((res) => {
        if (res.code === 0) {
          Message.success("笔记保存成功");
        } else {
          Message.error("笔记保存失败");
        }
      });
      this.isEditing = false;
    },
    downloadNote() {
      const blob = new Blob([this.selectedNote.noteContent], {
        type: "text/markdown",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.selectedNote.noteTitle}.md`;
      a.click();
      URL.revokeObjectURL(url);
    },
    toggleFullscreen() {
      const element = document.documentElement;
      if (!document.fullscreenElement) {
        element.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    confirmDeleteFolder(folderId) {
      this.deleteTarget = folderId;
      this.deleteType = "folder";
      this.deleteModalContent = "确定要删除该目录吗？不可恢复！";
      this.showDeleteConfirm = true;
    },
    confirmDeleteNote(directoryId, noteId) {
      this.deleteTarget = noteId;
      this.deleteNoteDirectoryId = directoryId;
      this.deleteType = "note";
      this.deleteModalContent = "确定要删除该笔记吗？不可恢复！";
      this.showDeleteConfirm = true;
    },
    confirmDelete() {
      const deleteId = this.deleteTarget;
      if (this.deleteType === "folder") {
        deleteDirectory({ directoryId: this.deleteTarget }).then((res) => {
          if (res.code === 0) {
            this.$Message.success("目录删除成功");
            this.folders = this.removeDirectory(this.folders, deleteId);
          } else {
            this.$Message.error("目录删除失败");
            return;
          }
        });
      } else if (this.deleteType === "note") {
        deleteNote({ noteId: deleteId }).then((res) => {
          if (res.code === 0) {
            this.traverseDirectories(this.folders, (folder) => {
              if (folder.directoryId === this.deleteNoteDirectoryId) {
                const index = folder.notesList.findIndex(
                  (note) => note.noteId === deleteId
                );
                if (index !== -1) {
                  folder.notesList.splice(index, 1);
                }
              }
            });
            this.$Message.success("笔记删除成功");
          } else {
            this.$Message.error("笔记删除失败");
            return;
          }
        });
      }
      this.deleteTarget = null;
      this.showDeleteConfirm = false;
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.deleteTarget = null;
      this.deleteType = "";
    },
    traverseDirectories(directories, action) {
      directories.forEach((directory) => {
        // 执行传入的操作函数
        action(directory);

        // 递归遍历子目录
        if (directory.children && directory.children.length > 0) {
          this.traverseDirectories(directory.children, action);
        }
      });
    },
    addFolderToParent(folders, parentId, newFolder) {
      for (const folder of folders) {
        if (folder.directoryId === parentId) {
          if (!folder.children) {
            folder.children = []; // 初始化子目录
          }
          folder.children.push(newFolder); // 添加新文件夹
          folder.isOpen = true;
          return true; // 添加成功
        }
        // 如果有子目录，递归查找
        if (folder.children) {
          const found = this.addFolderToParent(
            folder.children,
            parentId,
            newFolder
          );
          if (found) return true; // 找到并添加
        }
      }
      return false; // 未找到父文件夹
    },
    removeDirectory(directories, directoryId) {
      return directories.filter((directory) => {
        if (directory.directoryId === directoryId) {
          return false; // 删除该目录
        }
        // 递归处理子目录
        if (directory.children && directory.children.length > 0) {
          directory.children = this.removeDirectory(
            directory.children,
            directoryId
          );
        }
        return true; // 保留其他目录
      });
    },
  },
};
</script>

<style scoped>
.note-app {
  display: flex;
  height: 90vh;
  background: linear-gradient(to right, #f0f4f8, #e8f2fa);
}

.sidebar {
  background-color: #f8f8f8;
  padding: 15px;
  border-right: 1px solid #ddd;
  position: relative;
  transition: width 0.3s ease;
  width: 300px;
  overflow-y: auto; /* 启用垂直滚动条 */
  max-height: 100vh; /* 限制最大高度为浏览器视口高度 */
}

.content {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  transition: margin-left 0.3s;
}

.header {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tree-wrapper {
  padding: 10px;
  overflow-y: auto; /* 为树形目录添加滚动条 */
  max-height: calc(100vh - 300px); /* 设置为视口高度减去顶部部分高度 */
}

.note-details {
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
}

.note-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
  position: sticky; /* 使元素固定 */
  top: 0; /* 距离顶部的距离 */
  background-color: #ffffff; /* 背景色，确保内容不会透过 */
  z-index: 10; /* 确保在其他内容之上 */
  padding: 10px; /* 添加一些内边距 */
  border-bottom: 1px solid #ddd; /* 可选，底部边框 */
}

.action-button {
  border-radius: 4px;
  transition: all 0.2s ease;
}

.action-button:hover {
  transform: scale(1.05);
  background-color: #40a9ff; /* 更改hover颜色 */
  color: white;
}

.note-editor,
.note-preview {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
  }

  .sidebar.active {
    width: 300px;
  }

  .content {
    margin-left: 0;
  }

  .content.shift {
    margin-left: 300px;
  }
}
.demo-tree-render .ivu-tree-title {
  width: 100%;
}

.modal-body {
  margin: 20px 0;
}

.required {
  display: block;
  margin-bottom: 5px;
  color: #ff4d4f; /* 必填标识颜色 */
  font-weight: bold;
}

.noRequired {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.folder-input,
.note-input,
.folder-select,
.note-select {
  margin-bottom: 15px; /* 添加间距 */
}

.folder-select,
.note-select {
  width: 100%; /* 确保选择框占满宽度 */
}

.search-input,
.folder-input,
.note-input {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  transition: border-color 0.3s;
}

.search-input:focus,
.folder-input:focus,
.note-input:focus {
  border-color: #40a9ff; /* 焦点时的边框颜色 */
  outline: none;
}
h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.note-header {
  margin-bottom: 15px;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f0f4f8;
  border: 1px dashed #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.placeholder-content h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.placeholder-content p {
  font-size: 16px;
  color: #666;
}

.ivu-modal {
  padding: 20px;
  background-color: #f7f7f7;
}
.ivu-modal-header {
  font-size: 18px;
  font-weight: bold;
}
.note-app .sidebar .demo-tree-render div:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}
</style>