import request from '@/utils/request'


export function saveDirectory(data) {
    return request.post('/diary/notes/saveDirectory', data)
}
export function deleteDirectory(data) {
    return request.get('/diary/notes/deleteDirectory', { params: data })
}
export function listNotes(data) {
    return request.get('/diary/notes/listNotes', { params: data })
}
export function saveNote(data) {
    return request.post('/diary/notes/addNote', data)
}
export function deleteNote(data) {
    return request.get('/diary/notes/deleteNote', { params: data })
}
export function modifyNote(data) {
    return request.post('/diary/notes/modifyNote', data)
}
export function modifyNoteDirectory(data) {
    return request.post('/diary/notes/modifyNoteDirectory', data)
}